<template>
  <div>
    <form-wizard
      color="#3e74c7"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Létrehozás"
      next-button-text="Tovább"
      back-button-text="Vissza"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >

      <!-- account datails tab -->
      <tab-content title="Rendelés leadása">
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Rendelés adatai
            </h5>
            <small class="text-muted">
              Kérlek add meg a rendelés adatait illetve típusát.
            </small>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Rendelés típusa"
              label-for="select-order-type"
            >
              <b-form-select
                id="select-order-type"
                v-model="orderType"
                :options="orderTypes"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="orderType !== 2">
            <b-form-group
              label="Kiszállítás helye"
              label-for="order-place"
            >
              <b-form-input
                id="order-place"
                v-model="place"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Melyik projekthez kell?"
              label-for="order-project"
            >
              <b-form-select
                id="order-project"
                v-model="project"
                :options="projectOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="orderType == 0 || orderType == 1">
            <b-form-group
              label="Ki veszi át a rendelést?"
              label-for="receiver_id"
            >
              <b-form-select
                id="receiver_id"
                v-model="receiver_id"
                :options="receiverOptions"
                text-field="name"
                value-field="id"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-else>
            <b-form-group
              label="Ki engedélyezi a rendelést?"
              label-for="receiver_id"
            >
              <b-form-select
                id="receiver_id"
                v-model="receiver_id"
                :options="storageOptions"
                text-field="name"
                value-field="id"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-show="!orderType">
            <b-form-group
              label="Ki feladata?"
              label-for="order-project"
            >
              <b-form-select
                id="order-project"
                v-model="target_id"
                :options="targetOptions"
                text-field="name"
                value-field="id"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="orderType !== 2">
            <b-form-group
              label="Mikorra lenne rá szükség?"
              label-for="order-project"
            >
              <b-form-input
                id="order-project"
                type="date" max="9999-12-31"
                v-model="order_date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Megjegyzés"
              label-for="order-comment"
            >
              <b-form-textarea
                id="order-comment"
                v-model="comment"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="materials.length > 0">
          <b-col md="12" class="mt-2"><b>Elérhető tételek a projekthez</b></b-col>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Termék</th>
                <th scope="col">Mennyiség (elérhető / összesen)</th>
                <th scope="col">Mértékegység</th>
              </tr>
            </thead>
            <tr v-for="(material, index) in materials" :key="index">
              <td>
                {{ material.product }}
              </td>
              <td>
                {{ material.quantity }} / {{ material.total }}
              </td>
              <td>
                {{ material.unit }}
              </td>
            </tr>
          </table>
        </b-row>
        <b-row>
          <b-col md="12" class="mt-2"><b>Termékek hozzáadása</b></b-col>
          <b-col md="4">
            <b-form-group
              label="Termék neve"
              label-for="product"
            >
              <vue-autosuggest
                v-model="product"
                @selected="setProduct"
                :suggestions="filteredOptions2"
                :limit="10"
                :input-props="{id:'autosuggest__input2',class:'form-control', placeholder:'Termék neve'}"
                @input="onInputChange2"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.product }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Mennyiség"
              label-for="quantity"
            >
              <b-form-input
                id="quantity"
                type="number"
                v-model="quantity"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Mennyiségi egység"
              label-for="unit"
            >
              <vue-autosuggest
                v-model="unit"
                @selected="setUnit"
                :suggestions="filteredOptions"
                :limit="10"
                :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Mennyiségi egység'}"
                @input="onInputChange"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.supplier }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <br>
            <button class="btn btn-primary w-100" @click="addItem()">
              <feather-icon
                icon="PlusCircleIcon"
                size="16"
              />
            </button>
          </b-col>
        </b-row>
        <div>
          <table class="table">
            <tr v-for="(product, index) in products" :key="index">
              <td>
                {{ product.product }}
              </td>
              <td>
                {{ product.quantity }}
              </td>
              <td>
                {{ product.unit }}
              </td>
              <td class="float-right">
                <button class="btn btn-danger" @click="removeItem(index)"><feather-icon icon="XCircleIcon" size="16"/></button>
              </td>
            </tr>
          </table>
        </div>
      </tab-content>
    </form-wizard>

  </div>
</template>

<script>
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { VueAutosuggest } from 'vue-autosuggest'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    vSelect,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VueAutosuggest,
  },
  watch: {
    orderType(val) {
      if (val === false) {
        this.target_id = null
        this.enable_for_view = []
      }
    },
    project(val) {
      axios({
        method: 'get',
        url: `order/available-materials/${val}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.materials = res.data
      })
    },
  },
  data() {
    return {
      materials: [],
      orderType: '',
      place: '',
      orderTypes: [
        { value: 1, text: 'Belsős rendelés' },
        { value: 0, text: 'Külsős rendelés' },
      ],
      projectOptions: [],
      comment: '',
      order_date: '',
      product: '',
      products: [],
      storageOptions: [],
      productOptions: [],
      quantity: '',
      enable_for_view: [],
      datasuggest: [],
      datasuggest2: [],
      target_id: null,
      targetOptions: [],
      filteredOptions: [],
      filteredOptions2: [],
      receiver_id: null,
      receiverOptions: [],
      limit: 10,
      unit: '',
      project: '',
    }
  },
  mounted() {
    this.getProjectList()
    this.loadSupplier()
    this.loadProduct()
    this.getTargetOptions()
    this.getStorageRole()
    this.getReceiverOptions()
    if (JSON.parse(localStorage.getItem('user')).role.includes('restocking_role')) {
      this.orderTypes.push(
        { value: 2, text: 'Készlet feltöltés' },
      )
    }
  },
  methods: {
    getStorageRole() {
      axios({
        method: 'get',
        url: 'role/list/storage_role',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.storageOptions = res.data
      })
    },
    getReceiverOptions() {
      axios({
        method: 'get',
        url: 'role/list/create_order',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.receiverOptions = res.data
        this.receiver_id = JSON.parse(localStorage.getItem('user')).id
      })
    },
    getTargetOptions() {
      axios({
        method: 'get',
        url: 'role/list/order_target',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.targetOptions = res.data
      })
    },
    setUnit(text) {
      this.unit = text.item.name
    },
    setProduct(text) {
      this.product = text.item.name
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.datasuggest.filter(item => item.supplier.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    onInputChange2(text) {
      if (text === '' || text === undefined) {
        return
      }
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.datasuggest2.filter(item => item.product.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers
      this.filteredOptions2 = [{
        data: filteredData,
      }]
    },
    getProjectList() {
      axios({
        method: 'get',
        url: 'project/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.projectOptions.push({
          value: 0,
          text: 'Nem tartozik projekthez',
        })
        res.data.forEach(project => {
          this.projectOptions.push({
            value: project.id,
            text: project.name,
          })
        })
      })
    },
    removeItem(index) {
      this.products.splice(index, 1)
    },
    addItem() {
      if (this.product === '' || this.quantity === '' || this.unit === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        this.products.push({
          product: this.product,
          quantity: this.quantity,
          unit: this.unit,
          done: false,
        })
        this.product = ''
        this.quantity = ''
        this.unit = ''
      }
    },
    loadSupplier() {
      axios({
        method: 'get',
        url: 'order/autocomplete/supplier',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        res.data.forEach(supplier => {
          this.datasuggest.push({
            supplier: supplier.supplier,
            name: supplier.supplier,
          })
        })
      })
    },
    loadProduct() {
      axios({
        method: 'get',
        
        url: 'order/autocomplete/product',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        res.data.forEach(product => {
          this.datasuggest2.push({
            product: product.product,
            name: product.product,
          })
        })
      })
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Elküldve feldolgozásra',
          icon: 'LoaderIcon',
          variant: 'warning',
        },
      })
      axios({
        method: 'post',
        url: 'order/create',
        data: {
          orderType: this.orderType,
          place: this.place,
          project: this.project,
          comment: this.comment,
          products: this.products,
          target_id: this.target_id,
          enable_for_view: this.enable_for_view,
          receiver_id: this.receiver_id,
          order_date: this.order_date,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Rendelés sikeresen leadva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'monolit.order.own' })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
  .flatpickr-input{
    display: none !important;
  }
</style>
